import React, { Component } from 'react';
import { TextField, Button, Paper, Typography, Container } from '@mui/material';
import { login } from '../api';

class Login extends Component {
    constructor() {
        super();
        this.state = { username: '', password: '' };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitLogin = this.submitLogin.bind(this);
    }

    handleInputChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    submitLogin(event) {
        event.preventDefault();
        login(this.state)
            .then(token => window.location = '/')
            .catch(err => alert(err));
    }

    render() {
        return (
            <Container component="main" maxWidth="xs" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Paper elevation={3} style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h5">Log in</Typography>
                    <form onSubmit={this.submitLogin} style={{ width: '100%', marginTop: '10px' }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Username"
                            name="username"
                            onChange={this.handleInputChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            name="password"
                            type="password"
                            onChange={this.handleInputChange}
                        />
                        <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: '20px' }}>
                            Submit
                        </Button>
                    </form>
                </Paper>
            </Container>
        );
    }
}
export default Login;