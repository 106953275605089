// src/components/Patient.js
import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useQueryClient } from 'react-query';
import api, { fetchPatients } from "../api";
import { importPatientsCSV } from "../api";
import { getPatients, setPatients } from '../utils/indexedDB';
import { openDB } from 'idb';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Input } from "@mui/material";
import dayjs from "dayjs";
import EditPatientSourceModal from "./EditPatientSourceModal";
import AddPatientSourceModal from "./AddPatientSourceModal";
import AddPatientToProjectModal from "./AddPatientToProjectModal";

const Patient = ({ isDarkMode, refetchOnMount }) => {
  const queryClient = useQueryClient();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState(dayjs());
  const [sex, setSex] = useState("");
  const [editPatientSource, setEditPatientSource] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [addSourceModalOpen, setAddSourceModalOpen] = useState(false);
  const [addProjectModalOpen, setAddProjectModalOpen] = useState(false);
  const [currentPatientId, setCurrentPatientId] = useState(null);

  const { data: patients, isLoading, isError, refetch } = useQuery('patients', async () => {
    const response = await fetchPatients();
    await setPatients(response.data);
    return response.data;
  }, {
    staleTime: Infinity, // Don't automatically refetch
    cacheTime: Infinity,
  });

  useEffect(() => {
    refetchOnMount();
  }, [refetchOnMount]);

  const addPatientSource = async (newSource) => {
    try {
      // Make the API call
      const response = await api.post(`/patients/${currentPatientId}/add_source_to_patient/`, {
        source: newSource.source_id,
        patient_id: newSource.patient_id,
      });

      // Update the local cache
      const updatedPatients = await getPatients();
      const patientToUpdate = updatedPatients.find(p => p.id === currentPatientId);
      if (patientToUpdate) {
        patientToUpdate.sources = [...(patientToUpdate.sources || []), response.data];
        await setPatients(updatedPatients);
      }

      // Update React Query cache
      queryClient.setQueryData('patients', updatedPatients);

      // Refetch to ensure consistency
      refetch();
    } catch (error) {
      console.error("Error adding patient source:", error);
    }
  };

  const addPatient = async () => {
    try {
      const newPatient = {
        first_name: firstName,
        last_name: lastName,
        dob: dob.format("YYYY-MM-DD"),
        sex: sex,
      };
      await api.post("/patients/", newPatient);
      queryClient.invalidateQueries('patients');
      refetch();
      setFirstName("");
      setLastName("");
      setDob(dayjs());
      setSex("");
    } catch (error) {
      console.error("Error adding patient:", error);
    }
  };

  const deletePatient = async (id) => {
    try {
      await api.delete(`/patients/${id}/`);
      queryClient.invalidateQueries('patients');
      refetch();
    } catch (error) {
      console.error("Error deleting patient:", error);
    }
  };

  const handleEditPatientSource = (patientSource) => {
    setEditPatientSource(patientSource);
    setModalOpen(true);
  };

  const handleSavePatientSource = async (updatedPatientSource) => {
    try {
      await api.put(
        `/patient_sources/${updatedPatientSource.id}/`,
        updatedPatientSource
      );
      queryClient.invalidateQueries('patients');
      refetch();
    } catch (error) {
      console.error("Error updating patient source:", error);
    }
  };

  const handleDeletePatientSource = async (id) => {
    try {
      await api.delete(`/patient_sources/${id}/`);
      queryClient.invalidateQueries('patients');
      refetch();
    } catch (error) {
      console.error("Error deleting patient source:", error);
    }
  };

  const handleAddSourceClick = (patientId) => {
    setCurrentPatientId(patientId);
    setAddSourceModalOpen(true);
  };

  const handleAddToProjectClick = (patientId) => {
    setCurrentPatientId(patientId);
    setAddProjectModalOpen(true);
  };

  const addPatientToProject = async (patientId, projectId) => {
    try {
      await api.post("/projects/add_patients/", {
        patient_ids: [patientId],
        project_id: projectId,
      });
      queryClient.invalidateQueries('patients');
      refetch();
    } catch (error) {
      console.error("Error adding patient to project:", error);
    }
  };

  const handleCSVImport = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await importPatientsCSV(formData);
        console.log(response.data.message);
        queryClient.invalidateQueries('patients');
        refetch();
      } catch (error) {
        console.error("Error importing CSV:", error);
      }
    }
  };

  const handleDownloadTemplate = () => {
    const header = "first_name,last_name,dob,sex,source_name,source_patient_id,projects\n";
    const content = header + "John,Doe,1990-01-01,M,Hospital A,12345,Project1;Project2\n";
    const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "patient_template.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    { field: "first_name", headerName: "First Name", width: 150 },
    { field: "last_name", headerName: "Last Name", width: 150 },
    { field: "age", headerName: "Age", width: 100 },
    { field: "sex", headerName: "Sex", width: 100 },
    { field: "uuid", headerName: "UUID", width: 300 },
    {
      field: "sources",
      headerName: "Sources",
      width: 300,
      renderCell: (params) => (
        <div>
          {params.row.sources.map((patientSource) => (
            <Button
              key={patientSource.id}
              onClick={() => handleEditPatientSource(patientSource)}
            >
              {patientSource.source_name}
            </Button>
          ))}
          <Button onClick={() => handleAddSourceClick(params.row.id)}>+</Button>
        </div>
      ),
    },
    {
      field: "projects",
      headerName: "Projects",
      width: 300,
      renderCell: (params) => (
        <div>
          {params.row.projects.map((project) => (
            <Button
              key={project.id}
              variant="outlined"
              size="small"
              style={{ marginRight: 5 }}
            >
              {project.name}
            </Button>
          ))}
          <Button onClick={() => handleAddToProjectClick(params.row.id)}>+</Button>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => deletePatient(params.row.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Box width="98%" marginLeft="20px" marginRight="20px">
      <Typography variant="h4" gutterBottom>
        Patients
      </Typography>

      <Box sx={{ height: 600, width: "100%", marginBottom: 2 }}>
        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : isError ? (
          <Typography>Error loading patients</Typography>
        ) : (
          <DataGrid
            rows={patients}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        )}
      </Box>
      <EditPatientSourceModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        patientSource={editPatientSource}
        onSave={handleSavePatientSource}
        onDelete={handleDeletePatientSource}
        isDarkMode={isDarkMode}
      />
      <AddPatientSourceModal
        open={addSourceModalOpen}
        handleClose={() => setAddSourceModalOpen(false)}
        onAdd={addPatientSource}
        isDarkMode={isDarkMode}
      />
      <AddPatientToProjectModal
        open={addProjectModalOpen}
        handleClose={() => setAddProjectModalOpen(false)}
        onAdd={addPatientToProject}
        patientId={currentPatientId}
        isDarkMode={isDarkMode}
      />
      <Box
        sx={{
          padding: 2,
          backgroundColor: isDarkMode ? "#424242" : "#f9f9f9",
          borderRadius: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              variant="outlined"
              margin="normal"
              InputProps={{
                style: { backgroundColor: isDarkMode ? "#424242" : "#ffffff" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              variant="outlined"
              margin="normal"
              InputProps={{
                style: { backgroundColor: isDarkMode ? "#424242" : "#ffffff" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Sex</InputLabel>
              <Select
                value={sex}
                onChange={(e) => setSex(e.target.value)}
                label="Sex"
              >
                <MenuItem value="M">Male</MenuItem>
                <MenuItem value="F">Female</MenuItem>
                <MenuItem value="O">Other</MenuItem>
                <MenuItem value="U">Unknown</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of Birth"
                value={dob}
                onChange={(newValue) => setDob(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={addPatient}
              sx={{ marginTop: 2 }}
            >
              Add Patient
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginTop: 2, display: 'flex', gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadTemplate}
        >
          Download CSV Template
        </Button>
        <Input
          type="file"
          inputProps={{ accept: ".csv" }}
          onChange={handleCSVImport}
          style={{ display: "none" }}
          id="csv-import-input"
        />
        <label htmlFor="csv-import-input">
          <Button variant="contained" component="span">
            Import CSV
          </Button>
        </label>
      </Box>
    </Box>
  );
};

export default Patient;