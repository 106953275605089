import React, { useState, useCallback, useMemo } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import api, { removePatientFromProject, updateProject } from '../api';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Typography, TextField, Modal } from '@mui/material';

const Project = ({ isDarkMode }) => {
    const [selectedProject, setSelectedProject] = useState(null);
    const [apiKey, setApiKey] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const queryClient = useQueryClient();

    const { data: projects = [] } = useQuery('projects', async () => {
        const response = await api.get('/projects/');
        return response.data.map(project => ({
            ...project,
            patient_count: project.patients.length
        }));
    });

    const createProjectMutation = useMutation(
        (newProject) => api.post('/projects/', newProject),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('projects');
                setApiKey('');
            },
        }
    );

    const removePatientMutation = useMutation(
        ({ patientId, projectId }) => removePatientFromProject(patientId, projectId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('projects');
            },
        }
    );

    const updateProjectMutation = useMutation(
        (projectId) => updateProject(projectId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('projects');
            },
        }
    );

    const handleProjectClick = useCallback((project) => {
        setSelectedProject(project);
    }, []);

    const createProject = useCallback(() => {
        createProjectMutation.mutate({ api_key: apiKey });
    }, [apiKey, createProjectMutation]);

    const handleRemovePatient = useCallback((patientId) => {
        if (!selectedProject) return;
        removePatientMutation.mutate({ patientId, projectId: selectedProject.id });
    }, [selectedProject, removePatientMutation]);

    const handleUpdateProject = useCallback((projectId) => {
        updateProjectMutation.mutate(projectId);
    }, [updateProjectMutation]);

    const handleFormNameClick = useCallback((project, formName) => {
        if (selectedProject?.id !== project.id) {
            setSelectedProject(project);
        }
        const fields = Array.isArray(project.metadata) ? project.metadata.filter(meta => meta.form_name === formName).map(meta => meta.field_name) : [];
        setFormFields(fields);
        setOpenModal(true);
    }, [selectedProject]);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const ProjectDataGrid = useMemo(() => (
        <DataGrid
            rows={projects}
            columns={[
                { field: 'name', headerName: 'Name', width: 150 },
                { 
                    field: 'form_data', 
                    headerName: 'Form Names', 
                    width: 400, 
                    sortable: false, 
                    renderCell: (params) => {
                        const formNames = Array.isArray(params.row.metadata) ? [...new Set(params.row.metadata.map(meta => meta.form_name))] : [];
                        return (
                            <div>
                                {formNames.map((formName, index) => (
                                    <Button key={index} onClick={() => handleFormNameClick(params.row, formName)}>
                                        {formName}
                                    </Button>
                                ))}
                            </div>
                        );
                    }
                },
                { 
                    field: 'patient_count', 
                    headerName: 'Patient Count', 
                    width: 120,
                    renderCell: (params) => (
                        <Button onClick={() => handleProjectClick(params.row)}>
                            {params.value}
                        </Button>
                    )
                },
                {
                    field: 'actions',
                    headerName: 'Actions',
                    width: 120,
                    sortable: false,
                    renderCell: (params) => (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleUpdateProject(params.row.id)}
                        >
                            Update
                        </Button>
                    ),
                },
            ]}
            pageSize={5}
            rowsPerPageOptions={[5]}
        />
    ), [projects, handleFormNameClick, handleProjectClick, handleUpdateProject]);

    const PatientDataGrid = useMemo(() => (
        <DataGrid
            rows={selectedProject ? selectedProject.patients : []}
            columns={[
                { field: 'uuid', headerName: 'UUID', width: 300 },
                { field: 'first_name', headerName: 'First Name', width: 150 },
                { field: 'last_name', headerName: 'Last Name', width: 150 },
                { field: 'sex', headerName: 'Sex', width: 100 },
                { field: 'age', headerName: 'Age', width: 100 },
                {
                    field: 'actions', headerName: 'Actions', width: 150, renderCell: (params) => (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleRemovePatient(params.row.id)}
                        >
                            Remove
                        </Button>
                    )
                }
            ]}
            pageSize={5}
            rowsPerPageOptions={[5]}
        />
    ), [selectedProject, handleRemovePatient]);

    return (
        <Box width="98%" marginLeft='20px' marginRight='20px'>
            <Typography variant="h4">Projects</Typography>
            <Box sx={{ height: 600, width: '100%', marginTop: 2 }}>
                {ProjectDataGrid}
            </Box>
            <Box sx={{ height: 100, width: '100%', marginTop: 2 }}>
                <TextField
                    label="API Key"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={createProject}
                    sx={{ marginTop: 2 }}
                >
                    Create Project
                </Button>
            </Box>
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: isDarkMode ? '#424242' : 'background.paper', boxShadow: 24, p: 4 }}>
                    <Typography variant="h6">Field Names</Typography>
                    <ul>
                        {formFields.map((field, index) => (
                            <li key={index}>{field}</li>
                        ))}
                    </ul>
                    <Button onClick={handleCloseModal}>Close</Button>
                </Box>
            </Modal>
            {selectedProject && (
                <>
                    <Typography variant="h5" sx={{ marginTop: 4 }}>
                        Patients in Project: {selectedProject.name}
                    </Typography>
                    <Box sx={{ height: 400, width: '100%', marginTop: 2 }}>
                        {PatientDataGrid}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Project;