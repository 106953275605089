import React, { useState, useEffect } from "react";
import { Modal, Box, Button, TextField, Typography } from "@mui/material";

const EditPatientSourceModal = ({ open, handleClose, patientSource, onSave, onDelete, isDarkMode }) => {
  const [patientId, setPatientId] = useState("");

  useEffect(() => {
    if (patientSource) {
      setPatientId(patientSource.patient_id);
    }
  }, [patientSource]);

  const handleSubmit = () => {
    const updatedPatientSource = { ...patientSource, patient_id: patientId };
    onSave(updatedPatientSource);
    handleClose();
  };

  const handleDelete = () => {
    onDelete(patientSource.id);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ padding: 2, backgroundColor: isDarkMode ? "#424242" : "white", borderRadius: 1 }}>
        <Typography variant="h6">Edit Patient Source</Typography>
        <TextField
          label="Source ID"
          value={patientSource ? patientSource.source : ""}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Patient ID"
          value={patientId}
          onChange={(e) => setPatientId(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save
        </Button>
        <Button onClick={handleDelete} variant="outlined" color="secondary" sx={{ marginLeft: 1 }}>
          Delete
        </Button>
      </Box>
    </Modal>
  );
};

export default EditPatientSourceModal;