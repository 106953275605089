import { openDB } from 'idb';

const dbPromise = openDB('patientDB', 1, {
  upgrade(db) {
    db.createObjectStore('patients', { keyPath: 'id' });
  },
});

export const getPatients = async () => {
  const db = await dbPromise;
  return db.getAll('patients');
};

export const setPatients = async (patients) => {
  const db = await dbPromise;
  const tx = db.transaction('patients', 'readwrite');
  await tx.store.clear();
  patients.forEach(patient => tx.store.put(patient));
  await tx.done;
};

export const clearPatients = async () => {
    const db = await dbPromise;
    const request = db.transaction('patients', 'readwrite')
                    .objectStore('patients')
                    .clear();
    
    request.onsuccess = async (evt) => {
        console.log("Object Store patients emptied");

    request.onerror = (evt) => {
        console.log("Error emptying Object Store patients"); 
    }
    }

}