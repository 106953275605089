import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import Logo from './Logo';
import { isAuthenticated } from '../api';

const Home = () => {
    const handleGetStarted = () => {
        window.location.href = isAuthenticated() ? '/projects' : '/login';
    };

    return (
        <Container maxWidth="md" sx={{ textAlign: 'center', mt: 8 }}>
            <Box sx={{ mb: 4 }}>
                <Logo />
                <Typography variant="h2" component="h1" gutterBottom>
                    Welcome to DDx Core
                </Typography>
                <Typography variant="h5" component="p" gutterBottom>
                    Record Management for RedCap
                </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
                <Button variant="contained" color="primary" size="large" onClick={handleGetStarted}>
                    Get Started
                </Button>
            </Box>
        </Container>
    );
};

export default Home;