// src/App.js
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    Button,
    CssBaseline,
    IconButton, 
    Menu,
    MenuItem,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Home from './components/home';
import Project from "./components/Project";
import Patient from "./components/Patient";
import Source from "./components/Source";
import Login from './components/login';
import { isAuthenticated } from './api';
import { QueryClient, QueryClientProvider } from 'react-query';
import SessionTimeout from './components/SessionTimeout';
import { clearPatients } from './utils/indexedDB';

const queryClient = new QueryClient();

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));

class App extends Component {
    state = {
        anchorEl: null,
    };

    handleMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    logOut = async () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        queryClient.clear(); // Clear cached patient records

        // Clear IndexedDB storage
        await clearPatients();

        window.location.href = '/';
        this.handleClose();
    };
    
    render() {
        const { toggleTheme, isDarkMode } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Router>
                        <SessionTimeout timeout={1800000} /> {/* 30 minutes timeout */}
                        <CssBaseline />
                        <AppBar position="static">
                            <Toolbar>
                                <Typography variant="h6" style={{ flexGrow: 1 }}>
                                    <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
                                        DDX Core
                                    </Link>
                                </Typography>
                                {isAuthenticated() ? (
                                    <>
                                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                            <Button color="inherit" component={Link} to="/projects">
                                                Projects
                                            </Button>
                                            <Button color="inherit" component={Link} to="/patients">
                                                Patients
                                            </Button>
                                            <Button color="inherit" component={Link} to="/sources">
                                                Sources
                                            </Button>
                                            <Button color="inherit" onClick={this.logOut}>Log Out</Button>
                                        </Box>
                                        <IconButton
                                            size="large"
                                            edge="start"
                                            color="inherit"
                                            aria-label="menu"   
                                            sx={{ display: { xs: 'flex',    sm: 'flex', md: 'none' } }}
                                            onClick={this.handleMenu}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={open}
                                            onClose={this.handleClose}
                                        >
                                            <MenuItem component={Link} to="/projects" onClick={this.handleClose}>Projects</MenuItem>
                                            <MenuItem component={Link} to="/patients" onClick={this.handleClose}>Patients</MenuItem>
                                            <MenuItem component={Link} to="/sources" onClick={this.handleClose}>Sources</MenuItem>
                                            <MenuItem onClick={this.logOut}>Log Out</MenuItem>
                                        </Menu>
                                    </>
                                ) : (
                                    <Button color="inherit" component={Link} to="/login">
                                        Login
                                    </Button>
                                )}
                                <MaterialUISwitch 
                                    sx={{ m: 1 }} 
                                    onChange={toggleTheme} 
                                    checked={isDarkMode} 
                                />
                            </Toolbar>
                        </AppBar>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/projects" element={<Project />} />
                            <Route path="/patients" element={<Patient isDarkMode={isDarkMode} refetchOnMount={() => queryClient.invalidateQueries('patients')} />} />
                            <Route path="/sources" element={<Source isDarkMode={isDarkMode} />} />
                            <Route path="/login" element={<Login />} />
                        </Routes>
                    </Router>
                </LocalizationProvider>
            </QueryClientProvider>
        )
    };
};

export default App;