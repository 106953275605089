import React, { useState, useEffect } from "react";
import api from "../api";
import { Box, Button, TextField, Typography, Grid, MenuItem, Select, InputLabel, FormControl, Modal } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const Source = ({ isDarkMode }) => {
    const [sources, setSources] = useState([]);
    const [name, setName] = useState("");
    const [source_type, setSourceType] = useState("");
    const [source_url, setSourceUrl] = useState("");
    const [source_token, setSourceToken] = useState("");
    const [authentication_type, setAuthenticationType] = useState("NONE");
    const [source_password, setSourcePassword] = useState();
    const [source_username, setSourceUsername] = useState();
    const [has_encrypted_password, setHasEncryptedPassword] = useState();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedSource, setSelectedSource] = useState(null);
    const [editName, setEditName] = useState("");
    const [editSourceType, setEditSourceType] = useState("");
    const [editSourceUrl, setEditSourceUrl] = useState("");
    const [editSourceToken, setEditSourceToken] = useState("");
    const [editAuthenticationType, setEditAuthenticationType] = useState("NONE");
    const [editSourcePassword, setEditSourcePassword] = useState("");
    const [editSourceUsername, setEditSourceUsername] = useState("");
    const [editHasEncryptedPassword, setEditHasEncryptedPassword] = useState();

    useEffect(() => {
        fetchSources();
    }, []);

    const fetchSources = async () => {
        try {
            const response = await api.get("/sources/");
            setSources(response.data);
        } catch (error) {
            console.error("Error fetching sources:", error);
        }
    };

    const addSource = async () => {
        try {
            const newSource = {
                name,
                source_type,
                source_url,
                source_token,
                authentication_type,
                source_password,
                source_username,
            };
            const response = await api.post("/sources/", newSource);
            setSources([...sources, response.data]);
            resetForm();
        } catch (error) {
            console.error("Error adding sources:", error);
        }
    };

    const deleteSource = async (id) => {
        try {
            await api.delete(`/sources/${id}/`);
            setSources(sources.filter((source) => source.id !== id));
        } catch (error) {
            console.error("Error deleting source:", error);
        }
    };

    const openEditModal = (source) => {
        setSelectedSource(source);
        setEditName(source.name);
        setEditSourceType(source.source_type);
        setEditSourceUrl(source.source_url);
        if (source.source_token) setEditSourceToken(source.source_token);
        setEditAuthenticationType(source.authentication_type);
        if (source.source_password) setEditSourcePassword(source.source_password);
        if (source.source_username) setEditSourceUsername(source.source_username);
        if (source.has_encrypted_password) setEditHasEncryptedPassword(source.has_encrypted_password);

        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedSource(null);
        setEditModalOpen(false);
    };

    const editSource = async () => {
        try {
            const updatedSource = {
                name: editName,
                source_type: editSourceType,
                source_url: editSourceUrl,
                source_token: editSourceToken,
                authentication_type: editAuthenticationType,
                source_username: editSourceUsername,
                password: editSourcePassword,
            };
            const response = await api.put(`/sources/${selectedSource.id}/`, updatedSource);
            setSources(sources.map(source => source.id === selectedSource.id ? response.data : source));
            closeEditModal();
        } catch (error) {
            console.error("Error editing source:", error);
        }
    };

    const resetForm = () => {
        setName("");
        setSourceType("");
        setSourceUrl("");
        setSourceToken("");
        setAuthenticationType("NONE");
        setSourcePassword("");
        setSourceUsername("");
    };

    const columns = [
        { field: "name", headerName: "Name", width: 150 },
        { field: "source_type", headerName: "Type", width: 150 },
        {
            field: "actions",
            headerName: "Actions",
            width: 300,
            renderCell: (params) => (
                <div>
                    <Button variant="outlined" color="secondary" onClick={() => deleteSource(params.row.id)}>Delete</Button>
                    <Button variant="outlined" color="primary" onClick={() => openEditModal(params.row)}>Edit</Button>
                </div>
            ),
        },
    ];

    return (
        <Box width="94%" marginLeft="40px" marginRight="40px">
            <Typography variant="h4" gutterBottom>Sources</Typography>
            <Box sx={{ height: 600, width: "100%", marginBottom: 2 }}>
                <DataGrid rows={sources} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />
            </Box>
            <Box sx={{ padding: 2, backgroundColor: isDarkMode ? "#424242" : "#f9f9f9", borderRadius: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth label="Name" value={name} onChange={(e) => setName(e.target.value)} variant="outlined" margin="normal" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Type</InputLabel>
                            <Select value={source_type} onChange={(e) => setSourceType(e.target.value)} label="Type">
                                <MenuItem value="FHIR">FHIR</MenuItem>
                                <MenuItem value="API">API</MenuItem>
                                <MenuItem value="HL7">HL7</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth label="Url" value={source_url} onChange={(e) => setSourceUrl(e.target.value)} variant="outlined" margin="normal" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Authentication Type</InputLabel>
                            <Select value={authentication_type} onChange={(e) => setAuthenticationType(e.target.value)} label="Authentication Type">
                                <MenuItem value="PASSWORD">PASSWORD</MenuItem>
                                <MenuItem value="TOKEN">TOKEN</MenuItem>
                                <MenuItem value="NONE">NONE</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {authentication_type === "PASSWORD" && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Username" value={source_username} onChange={(e) => setSourceUsername(e.target.value)} variant="outlined" margin="normal" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Password" type="password" value={source_password} onChange={(e) => setSourcePassword(e.target.value)} variant="outlined" margin="normal"/>
                            </Grid>
                        </>
                    )}
                    {authentication_type === "TOKEN" && (
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Token" value={source_token} onChange={(e) => setSourceToken(e.target.value)} variant="outlined" margin="normal" />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={addSource} sx={{ marginTop: 2 }}>Add Source</Button>
                    </Grid>
                </Grid>
            </Box>
            <Modal open={editModalOpen} onClose={closeEditModal}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: isDarkMode ? '#424242' : 'background.paper', boxShadow: 24, p: 4 }}>
                    <Typography variant="h6" component="h2" gutterBottom>Edit Source</Typography>
                    <TextField fullWidth label="Name" value={editName} onChange={(e) => setEditName(e.target.value)} variant="outlined" margin="normal" />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Type</InputLabel>
                        <Select value={editSourceType} onChange={(e) => setEditSourceType(e.target.value)} label="Type">
                            <MenuItem value="FHIR">FHIR</MenuItem>
                            <MenuItem value="API">API</MenuItem>
                            <MenuItem value="HL7">HL7</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField fullWidth label="Url" value={editSourceUrl} onChange={(e) => setEditSourceUrl(e.target.value)} variant="outlined" margin="normal" />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Authentication Type</InputLabel>
                        <Select value={editAuthenticationType} onChange={(e) => setEditAuthenticationType(e.target.value)} label="Authentication Type">
                            <MenuItem value="PASSWORD">PASSWORD</MenuItem>
                            <MenuItem value="TOKEN">TOKEN</MenuItem>
                            <MenuItem value="NONE">NONE</MenuItem>
                        </Select>
                    </FormControl>
                    {editAuthenticationType === "PASSWORD" && (
                        <>
                            <TextField fullWidth label="Username" value={editSourceUsername} onChange={(e) => setEditSourceUsername(e.target.value)} variant="outlined" margin="normal" />
                            <TextField fullWidth label={editHasEncryptedPassword ? "Change Password" : "Set Password"} type="password" value={editSourcePassword} onChange={(e) => setEditSourcePassword(e.target.value)} variant="outlined" margin="normal"/>
                        </>
                    )}
                    {editAuthenticationType === "TOKEN" && (
                        <TextField fullWidth label="Token" value={editSourceToken} onChange={(e) => setEditSourceToken(e.target.value)} variant="outlined" margin="normal" />
                    )}
                    <Button variant="contained" color="primary" onClick={editSource} sx={{ marginTop: 2 }}>Save Changes</Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default Source;