import React, { useState, useEffect } from "react";
import { Modal, Box, Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import api from "../api";

const AddPatientSourceModal = ({ open, handleClose, onAdd, isDarkMode }) => {
  const [sourceId, setSourceId] = useState("");
  const [patientId, setPatientId] = useState("");
  const [availableSources, setAvailableSources] = useState([]);

  useEffect(() => {
    const fetchSources = async () => {
      try {
        const response = await api.get("/sources/");
        setAvailableSources(response.data);
      } catch (error) {
        console.error("Error fetching sources:", error);
      }
    };

    if (open) {
      fetchSources();
    }
  }, [open]);

  const resetForm = () => {
    setSourceId("");
    setPatientId("");
  };

  const handleSubmit = () => {
    const newSource = { source_id: sourceId, patient_id: patientId };
    onAdd(newSource);
    resetForm();
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ padding: 2, backgroundColor: isDarkMode ? "#424242" : "white", borderRadius: 1 }}>
        <Typography variant="h6">Add Patient Source</Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>Source</InputLabel>
          <Select
            value={sourceId}
            onChange={(e) => setSourceId(e.target.value)}
          >
            {availableSources.map((source) => (
              <MenuItem key={source.id} value={source.id}>
                {source.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Patient ID"
          value={patientId}
          onChange={(e) => setPatientId(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Add
        </Button>
      </Box>
    </Modal>
  );
};

export default AddPatientSourceModal;