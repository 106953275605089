// src/components/SessionTimeout.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query'; // Correct import
import { clearPatients } from '../utils/indexedDB'; // Import dbPromise

const SessionTimeout = ({ timeout = 1800000 }) => { // Default timeout of 30 minutes
  const [lastActivity, setLastActivity] = useState(Date.now());
  const queryClient = useQueryClient(); // Use the correct hook
  const navigate = useNavigate();

  const resetTimeout = () => {
    setLastActivity(Date.now());
  };

  useEffect(() => {
    const events = ['mousedown', 'keydown', 'scroll', 'touchstart'];
    events.forEach(event => document.addEventListener(event, resetTimeout));

    const interval = setInterval(async () => {
      if (Date.now() - lastActivity > timeout) {
        // Logout user
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        queryClient.clear(); // Clear cached patient records

        // Clear IndexedDB storage
        await clearPatients();

        navigate('/login');
      }
    }, 1000); // Check every second

    return () => {
      events.forEach(event => document.removeEventListener(event, resetTimeout));
      clearInterval(interval);
    };
  }, [lastActivity, timeout, navigate, queryClient]);

  return null;
};

export default SessionTimeout;