// src/index.js
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { lightTheme, darkTheme } from './theme';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

const Main = () => {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        // Check localStorage for the saved theme preference
        const savedTheme = localStorage.getItem('isDarkMode');
        return savedTheme === 'true'; 
    });

    const toggleTheme = () => {
        setIsDarkMode((prev) => {
            const newTheme = !prev;
            localStorage.setItem('isDarkMode', newTheme);
            return newTheme;
        });
    };

    return (
        <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
            <CssBaseline />
            <App toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
        </ThemeProvider>
    );
};

root.render(
    <React.StrictMode>
        <Main />
    </React.StrictMode>
);