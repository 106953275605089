import React, { useState, useEffect } from "react";
import { Modal, Box, Button, Typography, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import api from "../api";

const AddPatientToProjectModal = ({ open, handleClose, onAdd, patientId, isDarkMode }) => {
  const [projectId, setProjectId] = useState("");
  const [availableProjects, setAvailableProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await api.get("/projects/");
        setAvailableProjects(response.data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    if (open) {
      fetchProjects();
    }
  }, [open]);

  const handleSubmit = () => {
    onAdd(patientId, projectId);
    setProjectId("");
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ padding: 2, backgroundColor: isDarkMode ? "#424242" : "white", borderRadius: 1 }}>
        <Typography variant="h6">Add Patient to Project</Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>Project</InputLabel>
          <Select
            value={projectId}
            onChange={(e) => setProjectId(e.target.value)}
          >
            {availableProjects.map((project) => (
              <MenuItem key={project.id} value={project.id}>
                {project.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Add to Project
        </Button>
      </Box>
    </Modal>
  );
};

export default AddPatientToProjectModal;