// src/api.js
import axios from "axios";

const API_BASE_URL = "/api/";

const jwtToken = localStorage.getItem("access_token");

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${jwtToken}`,
};
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: headers,
});

// Function to refresh the token
const refreshToken = async () => {
  const refresh_token = localStorage.getItem("refresh_token");
  if (!refresh_token) {
    throw new Error("No refresh token available");
  }

  const response = await api.post(`/api-token-refresh/`, {
    refresh: refresh_token,
  });

  if (response.status === 200) {
    localStorage.setItem("access_token", response.data.access);
    return response.data.access;
  } else {
    throw new Error("Failed to refresh token");
  }
};

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newToken = await refreshToken();
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        return api(originalRequest);
      } catch (err) {
        // Clear tokens on error
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        // Navigate to login page
        window.location.href = "/login";
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export const addPatientsToProject = (patientIds, projectId) => {
  return api.post("/projects/add_patients/", {
    patient_ids: patientIds,
    project_id: projectId,
  });
};

export const removePatientFromProject = (patientId, projectId) => {
  return api.post("/projects/remove_patient/", {
    patient_id: patientId,
    project_id: projectId,
  });
};

export function login(data) {
  return api
    .post(`/api-token-auth/`, {
      username: data.username,
      password: data.password,
    })
    .then((response) => {
      localStorage.setItem("access_token", response.data.access);
      localStorage.setItem("refresh_token", response.data.refresh);
      return response.data;
    })
    .catch((err) => Promise.reject("Authentication Failed!"));
}

export function isAuthenticated() {
  return localStorage.getItem("access_token");
}

export const importPatientsCSV = (formData) => {
    const jwtToken = localStorage.getItem("access_token");
    return api.post('/patients/import_csv/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${jwtToken}`,
      },
    });
  };

export const fetchPatients = () => {
  return api.get(`/patients/?timestamp=${Date.now()}`);
};

export const updateProject = (projectId) => {
  return api.get(`/projects/${projectId}/update_project/`);
};

export default api;